import ApartmentIcon from "@mui/icons-material/Apartment";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Calendar, Layout } from "react-feather";

const clientSection = function (dataObj) {
  return [
    {
      href: "/",
      icon: Layout,
      title: "Главная страница",
    },
    {
      href: "/cars",
      icon: DriveEtaIcon,
      title: "Мои автомобили",
    },
    {
      href: "/recs",
      icon: CarRepairIcon,
      title: "Рекомендации",
    },
    {
      href: "/orders",
      icon: Calendar,
      title: "Заказы",
    },
    {
      badge: dataObj?.creditBalance || undefined,
      href: "/credits",
      icon: CardGiftcardIcon,
      title: "Бонусы",
    },
  ];
};

const companySection = function (dataObj) {
  let arr = [];

  arr = [
    ...arr,
    {
      href: "/services",
      icon: ShoppingBasketIcon,
      title: "Услуги и цены",
    },
  ];
  // if (dataObj?.includeBeta) {
  arr = [
    ...arr,
    {
      href: "/promotions",
      icon: CarRepairIcon,
      title: "Акции",
    },
  ];
  // }
  arr = [
    ...arr,
    {
      href: "/departments",
      icon: ApartmentIcon,
      title: "Контакты",
    },
  ];
  return arr;
};

const otherSection = function (dataObj) {
  let arr = [];

  if (dataObj?.includeBeta) {
    arr = [
      ...arr,
      {
        badge: dataObj?.unreadMessages || 2,
        href: "/chat",
        icon: ChatOutlinedIcon,
        title: "Чат",
      },
    ];
  }

  arr = [
    ...arr,
    {
      badge: dataObj?.currentBasketCount || undefined,
      href: "/basket",
      icon: ShoppingBasketIcon,
      title: "Корзина",
    },
  ];
  return arr;
};

const navItems = function (dataObj) {
  return [
    {
      pages: clientSection(dataObj),
      title: "",
    },
    {
      pages: companySection(dataObj),
      title: "",
    },
    {
      pages: otherSection(dataObj),
      title: "",
    },
  ];
};

export default navItems;
