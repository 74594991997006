import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { CarBlock } from "components/CarBlock";
import { PageTitle } from "components/PageTitle";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { getImageUrlByName, isIdentical, isObjectEmpty } from "data/functions";
import { ServiceBlock } from "pages/services/list/ServiceBlock";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PresentsDialog } from "./PresentsDialog";
import { RecsDialog } from "./RecsDialog";
import { ServicesDialog } from "./ServicesDialog";

export default function BasketPage() {
  const { dataObj } = GetData();
  const {
    basket,
    contextDelete,
    getPrice,
    isEmptyContext,
    sectionItemAdd,
    sectionItemDelete,
    sections,
  } = GetBasket();

  const [selected, setSelected] = useState({});
  const [allSelected, setAllSelected] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalType, setTotalType] = useState("");
  const [sale, setSale] = useState(0);
  const navigate = useNavigate();

  function onCheckBoxClick(value, section) {
    let val = (selected[section] ?? []).find((element) => element === value);
    if (val) {
      let newS = {
        ...selected,
        [section]: selected[section].filter((element) => element !== value),
      };
      setSelected(newS);
    } else {
      setSelected({
        ...selected,
        [section]: [...(selected[section] ?? []), value],
      });
    }
  }
  function checkAll() {
    let res = getAllBasket();
    if (isIdentical(res, selected)) setSelected({});
    else setSelected(res);
  }

  function getAllBasket() {
    let res = {};
    if (!isObjectEmpty(basket[sections?.recommendations])) {
      basket[sections?.recommendations].forEach((element) => {
        res = {
          ...res,
          [sections?.recommendations]: [
            ...(res[sections?.recommendations] ?? []),
            element,
          ],
        };
      });
    }
    if (!isObjectEmpty(basket[sections?.services])) {
      basket[sections?.services].forEach((element) => {
        res = {
          ...res,
          [sections?.services]: [...(res[sections?.services] ?? []), element],
        };
      });
    }
    if (!isObjectEmpty(basket[sections?.gifts])) {
      basket[sections?.gifts].forEach((element) => {
        res = {
          ...res,
          [sections?.gifts]: [...(res[sections?.gifts] ?? []), element],
        };
      });
    }
    return res;
  }

  React.useEffect(() => {
    setSelected(getAllBasket());
    contextDelete("registration");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket]);

  React.useEffect(() => {
    changeRegistration();
    setLocalPrices();
    let val = getAllBasket();

    if (isIdenticalLocal(val, selected) && !isObjectEmpty(val)) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  function isIdenticalLocal(main, second) {
    return (
      isIdenticalObjElements(main?.recommendations, second?.recommendations) &&
      isIdenticalObjElements(main?.services, second?.services) &&
      isIdenticalObjElements(main?.gifts, second?.gifts)
    );
  }
  function isIdenticalObjElements(main, second) {
    if (isIdentical(main, second)) return true;
    let error = false;
    for (let i = 0; i < (main ?? []).length; i++) {
      let find = (second ?? []).find((el) => el?.id === (main ?? [])[i]?.id);
      if (isObjectEmpty(find)) {
        error = true;
        break;
      }
    }
    return !error;
  }

  async function changeRegistration() {
    //need to rewrite
    await contextDelete("registration");
    Object.keys(selected).forEach((key) => {
      selected[key].forEach((element) => {
        sectionItemAdd("registration", element, key);
      });
    });
  }

  function deleteFromBasket() {
    //need to rewrite
    Object.keys(selected).forEach((key) => {
      selected[key].forEach((element) => {
        sectionItemDelete("basket", element, key);
      });
    });
  }

  function setLocalPrices() {
    let carID = dataObj?.usedCar?.car?.id || "all";
    let fSalePrice = 0;
    let fPrice = 0;
    let type = "";
    let typeEmpty = "";
    Object.keys(selected).forEach((key) => {
      selected[key].forEach((element) => {
        const priceObj = getPrice(element?.id, carID);
        if (priceObj) {
          fSalePrice = fSalePrice + priceObj?.salePrice;
          fPrice = fPrice + priceObj?.price;
          if (priceObj?.priceType === "min_price") type = priceObj?.priceType;
          if (priceObj?.priceType === "empty_price")
            typeEmpty = priceObj?.priceType;
        }
      });
    });
    if (!type && typeEmpty) type = typeEmpty;
    setTotalType(type);
    setTotalPrice(fSalePrice);
    setSale(fPrice - fSalePrice);
  }

  async function handleSubmit() {
    if (isObjectEmpty(selected)) return;
    navigate("/request");
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <PageTitle title="Корзина" />
        {!dataObj?.deviceWidth?.isMobile && <CarBlock />}
        <Box
          sx={{
            display: "flex",
            flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
            overflow: "hidden",
            height: "100%",
            // flexBasis: !dataObj?.deviceWidth?.isMobile ? "66%" : undefined,
          }}
        >
          <Box sx={{ width: "100%", overflow: "auto", position: "relative" }}>
            {dataObj?.deviceWidth?.isMobile && <CarBlock />}
            <Paper
              sx={{
                padding: "6px",
                // marginTop: "12px",
                borderRadius: 0,
                position: "sticky",
                top: 0,
                zIndex: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => {
                  checkAll();
                }}
                sx={{
                  textTransform: "none",
                  lineHeight: 1,
                  padding: "0 4px",
                  fontSize: "0.8125rem",
                }}
                variant="text"
              >
                <Checkbox
                  checked={allSelected}
                  disableRipple
                  // edge="start"
                  sx={{ padding: "0 4px" }}
                />
                Выбрать все
              </Button>
              {(!isObjectEmpty(selected?.recommendations) ||
                !isObjectEmpty(selected?.services) ||
                !isObjectEmpty(selected?.gifts)) && (
                <Button
                  color="error"
                  onClick={() => {
                    setSelected({});
                    deleteFromBasket();
                  }}
                  size="small"
                  sx={{
                    textTransform: "none",
                    padding: "0 4px",
                    fontSize: "0.8125rem",
                  }}
                  // variant="standart"
                  variant="text"
                >
                  Удалить выделенные
                </Button>
              )}
            </Paper>

            <Section
              array={basket[sections?.services]}
              dialogItem={<ServicesDialog />}
              onCheckBoxClick={onCheckBoxClick}
              section={sections?.services}
              selected={selected}
              title="Услуги"
            />
            {!isObjectEmpty(
              (dataObj?.recsArray ? dataObj?.recsArray : {})[
                dataObj?.usedCar?.car?.id
              ],
            ) && (
              <Section
                array={basket[sections?.recommendations]}
                dialogItem={<RecsDialog />}
                onCheckBoxClick={onCheckBoxClick}
                section={sections?.recommendations}
                selected={selected}
                title="Рекомендации"
              />
            )}
            {!isObjectEmpty(dataObj?.presentAccrue) && (
              <Section
                array={basket[sections?.gifts]}
                dialogItem={<PresentsDialog />}
                onCheckBoxClick={onCheckBoxClick}
                section={sections?.gifts}
                selected={selected}
                title="Бонусные услуги"
              />
            )}
          </Box>

          <Paper
            sx={{
              width: dataObj?.deviceWidth?.isMobile ? "100%" : "33%",
              padding: dataObj?.deviceWidth?.isMobile ? "12px" : "24px",
              display: "flex",
              justifyContent: dataObj?.deviceWidth?.isMobile
                ? "space-around"
                : "flex-start",
              flexDirection: dataObj?.deviceWidth?.isMobile
                ? "row-reverse"
                : "column",
            }}
          >
            <Button
              disabled={isEmptyContext("registration")}
              fullWidth={dataObj?.deviceWidth?.isMobile ? false : true}
              onClick={handleSubmit}
              size={dataObj?.deviceWidth?.isMobile ? "small" : "large"}
              sx={{ textTransform: "none" }}
              variant="contained"
            >
              Перейти к оформлению
            </Button>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  padding: "0.4rem 12px 0px 12px",
                  width: "100%",
                  gap: "4px",
                }}
              >
                {totalPrice > 0 ? (
                  <React.Fragment>
                    {!dataObj?.deviceWidth?.isMobile && (
                      <Typography sx={{ textAlign: "right" }} variant="h6">
                        Цена:
                      </Typography>
                    )}
                    <Typography
                      sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                      variant="h6"
                    >
                      {totalType === "min_price" || totalType === "empty_price"
                        ? "от "
                        : ""}
                      {totalPrice} руб.
                    </Typography>
                  </React.Fragment>
                ) : (
                  totalType === "empty_price" && (
                    <Typography sx={{ textAlign: "right" }} variant="h6">
                      Цена по запросу
                    </Typography>
                  )
                )}
              </Box>

              {sale > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                    padding: "0px 12px 0.4rem 12px",
                    gap: "4px",
                  }}
                >
                  {!dataObj?.deviceWidth?.isMobile && (
                    <Typography sx={{ textAlign: "right" }} variant="body1">
                      Скидка:
                    </Typography>
                  )}
                  <Typography
                    color="error"
                    sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                    variant="body1"
                  >
                    - {sale} руб.
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function Section(props) {
  const [open, setOpen] = useState(false);
  const { getPrice, sectionItemDelete } = GetBasket();
  const { dataObj } = GetData();
  // if (isObjectEmpty(props?.array)) return;
  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", padding: "0.4rem 0" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 12px",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              padding: "0 4px",
              fontWeight: 400,
              textAlign: "start",
            }}
            variant="body1"
          >
            {props?.title}
          </Typography>

          <Add
            aria-hidden={false}
            onClick={() => setOpen(true)}
            sx={{ opacity: 0.5 }}
          />
        </Box>
        <Divider />
        {!isObjectEmpty(props?.array) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
              flexWrap: "wrap",
              padding: "0.4rem 1rem",
              // justifyContent: "space-around",
            }}
          >
            {(!isObjectEmpty(props?.array) ? props?.array : []).map(
              (element, index) => {
                let carID = dataObj?.usedCar?.car?.id || "all";
                const priceObj = getPrice(element?.id, carID) || {};
                let check =
                  !isObjectEmpty(
                    (!isObjectEmpty(props?.selected[props?.section])
                      ? props?.selected[props?.section]
                      : []
                    ).find((el) => el?.id === element?.id),
                  ) || false;
                const name = element?.name || element?.text || "";
                return (
                  <ServiceBlock
                    borderLeft={
                      element?.urgency > 1
                        ? "1px solid rgb(255 0 0)"
                        : undefined
                    }
                    boxShadow={element?.urgency > 1 ? "#ff000017" : undefined}
                    deleteComponent={
                      <Delete
                        onClick={() =>
                          sectionItemDelete("basket", element, props?.section)
                        }
                      />
                    }
                    height=""
                    iconElement={
                      <Checkbox
                        checked={check || false}
                        disableRipple
                        onChange={() => {
                          props?.onCheckBoxClick(element, props?.section);
                        }}
                        edge="start"
                        // inputProps={{ "aria-labelledby": labelId }}
                        size="small"
                        sx={{
                          padding: "0.4rem",
                        }}
                        tabIndex={-1}
                      />
                    }
                    image={getImageUrlByName(name)}
                    key={index}
                    onBasket={true}
                    price={
                      priceObj?.price ? (
                        priceObj?.price > priceObj?.salePrice ? (
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <strike>{priceObj?.price}</strike> руб.
                              </div>
                              {priceObj?.salePrice + " руб."}
                            </Box>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {priceObj?.priceType === "min_price"
                              ? "от " + priceObj?.salePrice + " руб."
                              : priceObj?.salePrice + " руб."}
                          </React.Fragment>
                        )
                      ) : (
                        ""
                      )
                    }
                    thereIsEl={true}
                    title={name}
                  />
                );
              },
            )}
          </Box>
        )}
      </Box>
      {props?.dialogItem &&
        React.cloneElement(props?.dialogItem, {
          setOpen: setOpen,
          status: open,
        })}
    </React.Fragment>
  );
}
