import { Box } from "@mui/material";
import { GetModelQuery } from "components/gql/queris/GetModel";
import { isObjectEmpty } from "data/functions";

import React, { useEffect, useState } from "react";

export default function ImageBlock(props) {
  const [image, setImage] = useState("");
  const [refetch] = GetModelQuery();
  useEffect(() => {
    getModelImage(props?.carAddData?.mark, props?.carAddData?.model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.carAddData?.model]);

  async function getModelImage(marka, model) {
    if (isObjectEmpty(model) || isObjectEmpty(model?.id)) {
      setImage("");
      return;
    }

    if (model?.presignURL?.URL) {
      setImage(model?.presignURL?.URL);
      return;
    }

    let { data } = await refetch({
      variables: {
        markId: marka?.id,
        modelId: model?.id,
      },
    });
    if (data && data.exch_getModel?.presignURL?.URL) {
      setImage(data.exch_getModel?.presignURL?.URL);
    } else {
      setImage("");
    }
  }

  return (
    <React.Fragment>
      {image ? (
        <Box
          sx={{
            margin: "0.5rem 1rem",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <img
            alt={props?.marka + " " + props?.model}
            src={image}
            style={{ overflow: "hidden" }}
          />
        </Box>
      ) : undefined}
    </React.Fragment>
  );
}
