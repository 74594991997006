import { Description } from "@mui/icons-material/";
import { Box, Button, Chip, Typography } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { dateToString, isIdentical, parseRFC3339 } from "data/functions";
import {
  getCarName,
  getDateMinusTime,
  getOrderIcon,
  isObjectEmpty,
  isOrderStatusActive,
  translateOrderStatus,
  translateOrderType,
} from "data/functions";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ReviewDialog } from "components/ReviewDialog";
import { constants } from "data/globals";

function OrderData(props) {
  const [secondPart, setSecondPart] = React.useState(false);
  const [trirdPart, setTrirdPart] = React.useState(false);

  React?.useEffect(() => {
    setSecondPart(secondPartCheck(props?.item));
    setTrirdPart(trirdPartCheck(props?.item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.item]);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        margin: props?.margin ? props?.margin : "8px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "4px",
        position: "relative",
        padding: "4px",
        // height: "100%",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
      }}
    >
      <Box
        onClick={(e) =>
          navigate(`/orders/${props?.item.orderID}`, {
            state: { orderID: props?.item.orderID },
          })
        }
        sx={{
          "&:hover": {
            backgroundColor: "rgb(0 0 0 / 2%)",
          },
          cursor: "pointer",
        }}
      >
        <OrderTitle {...props} />
        <OrderBody secondPart={secondPart} trirdPart={trirdPart} {...props} />
      </Box>
      <OrderButtons trirdPart={trirdPart} {...props} />
    </Box>
  );
}

function OrderTitle(props) {
  const { dataObj } = GetData();
  return (
    <Box sx={{ margin: "2px 0" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "nowrap",
            position: "relative",
          }}
        >
          {getOrderIcon(props?.item?.type)}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {props?.item?.type && (
              <Typography
                // variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
                sx={{
                  lineHeight: "1.2",
                  fontSize: dataObj?.deviceWidth?.isMobile
                    ? "0.87rem"
                    : "0.875rem",
                }}
              >
                {translateOrderType(props?.item?.type)}{" "}
                {"№" + props?.item?.docNumber}
              </Typography>
            )}
            {!isObjectEmpty(props?.item?.docDate) &&
              translateOrderType(props?.item?.type) !== constants.ruTypeZR && (
                <Typography
                  sx={{
                    lineHeight: "1.2",
                    fontSize: dataObj?.deviceWidth?.isMobile
                      ? "0.87rem"
                      : "0.875rem",
                  }}
                  // variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
                >
                  {parseRFC3339(props?.item?.docDate)
                    ? "от " +
                      dateToString(parseRFC3339(props?.item?.docDate), false)
                    : ""}
                </Typography>
              )}
            {!isObjectEmpty(props?.item?.planDate) &&
              translateOrderType(props?.item?.type) === constants.ruTypeZR && (
                <Typography
                  sx={{
                    lineHeight: "1.2",
                    fontSize: dataObj?.deviceWidth?.isMobile
                      ? "0.87rem"
                      : "0.875rem",
                  }}
                  // variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
                >
                  {"на " +
                    dateToString(parseRFC3339(props?.item?.planDate), true)}
                </Typography>
              )}
          </Box>
        </Box>
        {props?.item?.status && (
          <Box>
            <Chip
              label={translateOrderStatus(props?.item?.status)}
              sx={{
                "& .MuiChip-label": {
                  padding: "0px 8px",
                },
                height: "20px",
                margin: "0px 4px",
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

function OrderBody(props) {
  const { dataObj } = GetData();

  const car = (dataObj.carArrWithSold ?? []).find((element) => {
    if (isIdentical(element?.car?.id, props?.item?.carID)) return true;
    return false;
  });

  return (
    <React.Fragment>
      {props?.secondPart && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "4px 0 0 4px",
          }}
        >
          {props?.item?.carID && (
            <Typography
              // variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
              sx={{
                lineHeight: "1.2",
                fontSize: dataObj?.deviceWidth?.isMobile
                  ? "0.87rem"
                  : "0.875rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              {car?.car?.marka?.imageUrl ? (
                <img
                  alt={"Марка"}
                  src={car?.car?.marka?.imageUrl}
                  style={{
                    overflow: "hidden",
                    width: "24px",
                    margin: "0 0 0 -2px",
                  }}
                />
              ) : undefined}

              {getCarName(
                props?.item?.carID,
                dataObj?.carArrWithSold,
                "",
                true,
              )}
            </Typography>
          )}
          {!isObjectEmpty(parseRFC3339(props?.item?.planDate)) &&
            translateOrderType(props?.item?.type) === constants.ruTypeZP && (
              <Typography
                // variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
                sx={{
                  lineHeight: "1.2",
                  fontSize: dataObj?.deviceWidth?.isMobile
                    ? "0.87rem"
                    : "0.875rem",
                }}
              >
                {"Срок поставки: " +
                  dateToString(parseRFC3339(props?.item?.planDate), false)}
              </Typography>
            )}

          {parseInt(props?.item?.docSum?.total) > 0 && (
            <Typography
              // variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
              sx={{
                lineHeight: "1.2",
                fontSize: dataObj?.deviceWidth?.isMobile
                  ? "0.87rem"
                  : "0.875rem",
              }}
            >
              Сумма: {props?.item?.docSum?.total + " руб."}
            </Typography>
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

function OrderButtons(props) {
  return (
    <React.Fragment>
      {props?.trirdPart && (
        <Box>
          {parseRFC3339(props?.item?.docDate) > getDateMinusTime(3) &&
            !isOrderStatusActive(props?.item?.status) && (
              <Button
                onClick={() => {
                  props?.setOpenR(true);
                }}
                size="small"
                sx={{
                  textTransform: "none",
                  padding: "0px 0.4rem",
                  margin: "4px",
                }}
                variant="outlined"
              >
                Оценить
              </Button>
            )}
          {!isObjectEmpty(props?.item?.payDebt) && (
            <Button
              size="small"
              sx={{
                textTransform: "none",
                padding: "0px 0.4rem",
                margin: "4px",
              }}
              variant="outlined"
            >
              Оплатить
            </Button>
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

export function OrderBlock(props) {
  const [openR, setOpenR] = React.useState(false);

  return (
    <React.Fragment>
      <Box key={props?.index} sx={{ width: "100%" }}>
        <OrderData {...props} setOpenR={setOpenR} />
      </Box>
      <ReviewDialog
        open={openR}
        setOpen={setOpenR}
        title={
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Description color="dimblue" />
            {translateOrderType(props?.item?.type) +
              " №" +
              props?.item?.docNumber}
          </Box>
        }
      />
    </React.Fragment>
  );
}

function secondPartCheck(item) {
  if (item?.carID || parseInt(item?.docSum?.total) > 0) {
    return true;
  } else {
    return false;
  }
}

function trirdPartCheck(item) {
  if (
    (parseRFC3339(item?.docDate) > getDateMinusTime(3) &&
      !isOrderStatusActive(item?.status)) ||
    parseInt(!isObjectEmpty(item?.payDebt)) > 0
  ) {
    return true;
  } else {
    return false;
  }
}
