import { useMutation } from "@apollo/client";
import { EXT_LOGOUT } from "components/gql/gql_queries";
import { consoleBeauty } from "data/functions";

export function ExtLogoutMutation() {
  const [mutation, variables] = useMutation(EXT_LOGOUT, {
    onCompleted: (data) => {
      if (data?.auth_extLogOut.code === 200) {
        consoleBeauty("Сессия завершена", "GraphQL", "darkgreen");
      } else {
        consoleBeauty(
          "ExtLogout: " + data?.auth_extLogOut?.message,
          "GraphQL",
          "red",
        );
        sessionStorage.setItem(
          "error_" + new Date(Date.now()).toISOString(),
          "ExtLogout: " + data?.auth_extLogOut?.message,
        );
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "ExtLogin: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [mutation, variables];
}
