import { Box } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { BoxFolder } from "data/styleGlobals";
import React from "react";
import { FolderBlock } from "./FolderBlock";

export default function FolderList(props) {
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "1.0rem 0.4rem 0.4rem 0.4rem",
          gap: "2%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          // justifyContent: "space-evenly",
          width: dataObj?.deviceWidth?.isMobile ? "100%" : undefined,
        }}
      >
        {[...(!isObjectEmpty(dataObj?.serviceList) ? dataObj?.serviceList : [])]
          .sort((a, b) => (a.folder === true ? 1 : -1))
          .map((element, ind) => {
            if (
              (props?.parent?.id || "") === element?.parent_id &&
              element.folder === true
            ) {
              return (
                <BoxFolder
                  key={ind}
                  onClick={() => props?.setParent(element)}
                  sx={{
                    width: dataObj?.deviceWidth?.isMobile
                      ? "49%"
                      : dataObj?.deviceWidth?.isPad
                        ? "24%"
                        : "18%",
                  }}
                >
                  <FolderBlock folder={true} title={element.name} />
                </BoxFolder>
              );
            } else return "";
          })}
      </Box>
    </React.Fragment>
  );
}
