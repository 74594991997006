import styled from "@emotion/styled";
import { isObjectEmpty } from "data/functions";
import React, { useState } from "react";

import { AllInbox, Construction } from "@mui/icons-material/";
import {
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { GetData } from "contexts/DataContext";

const TableCell = styled(MuiTableCell)`
  padding: 4px;
`;

export function ProfileTable(props) {
  const [tabValue, setTabValue] = useState(() => {
    if (!isObjectEmpty(props?.docItem?.operations)) return "operations";
    else return "parts";
  });
  const { dataObj } = GetData();
  React.useEffect(() => {
    setTabValue(() => {
      if (!isObjectEmpty(props?.docItem?.operations)) return "operations";
      else return "parts";
    });
  }, [props?.docItem]);

  return (
    <React.Fragment>
      {(!isObjectEmpty(props?.docItem?.operations) ||
        !isObjectEmpty(props?.docItem?.parts)) && (
        <React.Fragment>
          <Tabs
            centered
            onChange={(e, v) => setTabValue(v)}
            sx={{ height: "3.5rem" }}
            value={tabValue}
            variant="fullWidth"
          >
            {!isObjectEmpty(props?.docItem?.operations) && (
              <Tab
                disabled={props?.docItem?.operations ? false : true}
                icon={<Construction />}
                iconPosition="start"
                label="Услуги"
                value="operations"
              />
            )}
            <Tab
              disabled={props?.docItem?.parts ? false : true}
              icon={<AllInbox />}
              iconPosition="start"
              label="Товары"
              value="parts"
            />
          </Tabs>
          <Paper
            sx={{
              marginTop: 3,
              overflow: "auto",
              scrollbarWidth: "thin",
              background: "transparent",
            }}
          >
            <Table
              size={dataObj?.deviceWidth?.isMobile ? "small" : undefined}
              stickyHeader
              sx={{
                width: "100%",
                padding: dataObj?.deviceWidth?.isMobile ? "0.4rem" : undefined,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell align="center">шт.</TableCell>
                  <TableCell align="center">Всего</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isObjectEmpty(
                  tabValue === "operations"
                    ? props?.docItem?.operations
                    : props?.docItem?.parts,
                ) &&
                  (tabValue === "operations"
                    ? props?.docItem?.operations
                    : props?.docItem?.parts
                  ).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="center">{item.count}</TableCell>
                      <TableCell
                        align="center"
                        sx={{ padding: "0px !important", lineHeight: "1" }}
                      >
                        {item.discount > 0 ? (
                          <div>
                            {item.sum} руб.
                            <br />
                            <sub>
                              <strike>{item.sum + item.discount}</strike> руб.
                            </sub>
                          </div>
                        ) : (
                          item.sum + " руб."
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                {!isObjectEmpty(
                  tabValue === "operations"
                    ? props?.docItem?.operations
                    : props?.docItem?.parts,
                ) && (
                  <TableRow>
                    <TableCell align="right" colSpan={2}>
                      {tabValue === "operations"
                        ? "Сумма работ:"
                        : "Сумма товаров:"}
                    </TableCell>
                    <TableCell align="right">
                      {
                        props?.docItem.docSum[
                          tabValue === "operations" ? "operations" : "parts"
                        ]
                      }
                      руб.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
