import React, { useEffect, useState } from "react";

import { Box, Paper } from "@mui/material";
import { PageTitle } from "components/PageTitle";

import { NewDealMutation } from "components/gql/mutations/AddNewDeal";

import { GetData } from "contexts/DataContext";
import {
  createDealVariables,
  createTextComment,
  isIdentical,
  isObjectEmpty,
  roundMinutes,
} from "data/functions";

import { LoadingButton } from "@mui/lab";
import CommentField from "pages/deal/fields/CommentField";
import DateField from "pages/deal/fields/DateField";
import DepField from "pages/deal/fields/DepField";

import { CarBlock } from "components/CarBlock";
import PhoneField from "pages/deal/fields/PhoneField";
import PromocodeField from "pages/deal/fields/PromocodeField";

import { OrdersListQuery } from "components/gql/queris/GetOrdersList";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { updateOrders } from "data/functions";

import { RegistrationBlock } from "components/registration/RegistrationBlock";
import { GetBasket } from "contexts/BasketContext";
import { useLocation } from "react-router-dom";
import RegionField from "./fields/RegionField";

export default function DealAdd() {
  const { dataObj, setDataObj } = GetData();
  const { contextDelete, isEmptyContext, registration } = GetBasket();
  const { cInfo } = GetCInfo();
  const { setAlert } = GetAlertData();

  let location = useLocation();

  const [name, setName] = useState(dataObj?.clientName || "");
  const [filesArray, setFilesArray] = useState([]);

  const [orderListGQL] = OrdersListQuery();

  // const [phones, setPhones] = useState(dataObj?.clientPhones || {});
  const [usedPhone, setUsedPhone] = useState(dataObj?.usedPhone || "");
  const [comment, setComment] = useState("");
  const [promocode, setPromocode] = useState("");
  const [date, setDate] = useState(roundMinutes(new Date()));

  const [department, setDepartment] = useState(() => {
    if (location?.state && location?.state?.departmentItem)
      return location?.state?.departmentItem;
    if (!isObjectEmpty(cInfo?.departments)) {
      if (cInfo?.preferedRegion?.name) {
        let find = cInfo?.departments.find(
          (dep) => dep?.region === cInfo?.preferedRegion?.name,
        );
        if (find) return find;
        else return null;
      } else {
        return null;
      }
    } else return null;
  });

  useEffect(() => {
    if (!isObjectEmpty(cInfo?.departments)) {
      if (
        location?.state &&
        location?.state?.departmentItem &&
        location?.state?.departmentItem?.region ===
          cInfo?.preferedRegion?.name &&
        !isIdentical(department, location?.state?.departmentItem)
      ) {
        setDepartment(location?.state?.departmentItem);
      } else {
        if (cInfo?.preferedRegion?.name) {
          let find = cInfo?.departments.find(
            (dep) =>
              (dep?.region || "") === (cInfo?.preferedRegion?.name || ""),
          );
          if (find) setDepartment(find);
          else setDepartment(null);
        } else setDepartment(cInfo?.departments[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.preferedRegion]);

  const [departments, setDepartments] = useState(cInfo?.departments || []);
  const [logined, setLogined] = useState(true);
  const [addNewDeal, newDealVars] = NewDealMutation();

  useEffect(() => {
    if (
      !newDealVars?.loading &&
      !isObjectEmpty(newDealVars?.data) &&
      newDealVars?.data?.dealAdd?.success
    ) {
      contextDelete("registration");
      setComment("");
    }
    // eslint-disable-next-line
  }, [newDealVars?.data]);

  useEffect(() => {
    // console.log(dataObj);
    setDataFromDataArr();

    // eslint-disable-next-line
  }, [dataObj.clientName, cInfo?.departments, dataObj.usedPhone]);

  function setDataFromDataArr() {
    if (dataObj.clientName) {
      setName(dataObj?.clientName);
    }
    if (dataObj.clientID) {
      setLogined(true);
    } else {
      setLogined(false);
    }
    if (cInfo?.departments) {
      setDepartments(cInfo?.departments);
    }
    // if (dataObj.clientPhones) {
    //   if (
    //     !isObjectEmpty(dataObj?.clientPhones) &&
    //     dataObj?.clientPhones !== null
    //   ) {
    //     setPhones(dataObj?.clientPhones);
    //   }
    // }
    if (dataObj.usedPhone) {
      setUsedPhone(dataObj?.usedPhone);
    }
    //
  }

  const handleSubmit = async () => {
    if (date && usedPhone && !isEmptyContext("registration")) {
      let dealDate = new Date(date).toISOString();
      let recIDs = [];
      (registration?.recommendations || []).forEach((element) => {
        recIDs = [...recIDs, element?.id];
      });
      let servIDs = [];
      (registration?.services || []).forEach((element) => {
        servIDs = [...servIDs, element?.id];
      });
      let giftIDs = [];
      (registration?.gifts || []).forEach((element) => {
        giftIDs = [...giftIDs, element?.id];
      });
      let fileIDs = [];
      (filesArray || []).forEach((element) => {
        fileIDs = [...fileIDs, element?.fileId];
      });
      let params = {
        clientID: dataObj?.clientID,
        companyUUID: cInfo?.UUID,
        dealDate: dealDate,
        departmentID: department?.ID,
        fileIDs: fileIDs,
        giftIDs: giftIDs,
        logined: logined,
        name: name,
        platform: dataObj?.platform,
        promocode: promocode,
        recIDs: recIDs,
        servIDs: servIDs,
        usedCar: dataObj?.usedCar,
        usedPhone: usedPhone,
      };
      let commentJoined = createTextComment(params);
      params = {
        ...params,
        comment: commentJoined,
      };
      let variables = createDealVariables(params);
      // console.log(variables);
      let { data } = await addNewDeal({
        variables: variables,
      });
      if (data && data?.dealAdd?.code === 200) {
        updateOrders(orderListGQL, { dataObj, setDataObj });
      }
    } else {
      setAlert(
        "Для отправки\n заявки заполните\n Номер телефона и Услуги",
        "warning",
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <PageTitle title="Запись на ремонт" />
      <CarBlock />
      <Box sx={{ overflow: "auto" }}>
        <Box component="form" sx={{ padding: "0 20px 0px 20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "unset",
            }}
          >
            <RegionField
              department={department}
              setDepartment={setDepartment}
            />
            <DepField
              department={department}
              departments={departments}
              setDepartment={setDepartment}
            />
          </Box>
          <Paper
            sx={{
              overflow: "auto",
              background: "transparent",
              boxShadow: "none",
            }}
          >
            <RegistrationBlock />
          </Paper>
          {dataObj?.includeBeta && (
            <PromocodeField promocode={promocode} setPromocode={setPromocode} />
          )}
          <DateField date={date} setDate={setDate} />
          <PhoneField
            setUsedPhone={setUsedPhone}
            usedPhone={usedPhone}
            // logined={logined}
          />
          <CommentField
            comment={comment}
            filesArray={filesArray}
            setComment={setComment}
            setFilesArray={setFilesArray}
          />
        </Box>
      </Box>{" "}
      <LoadingButton
        fullWidth
        loading={newDealVars?.loading}
        onClick={handleSubmit}
        sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
        variant="outlined"
        // loadingPosition="start"
      >
        Отправить заявку
      </LoadingButton>
    </Box>
  );
}
