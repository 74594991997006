import { Box, Button, Paper, Typography } from "@mui/material";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import {
  getCarName,
  getImageUrlByName,
  isIdentical,
  isObjectEmpty,
} from "data/functions";
import React, { useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import ServiceTitle from "../list/ServiceTitle";

export default function ServiceProfile(props) {
  const { dataObj } = GetData();
  const {
    basket,
    getPrice,
    inBasket,
    prices,
    sectionItemAdd,
    sectionItemDelete,
    sections,
  } = GetBasket();

  let location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const serviceID = location.state?.serviceID || params.id;
  const [item, setItem] = useState({});
  const [price, setPrice] = useState(0);
  const [sale, setSale] = useState(0);
  const [priceType, setPriceType] = useState("");
  const [added, setAdded] = useState(false);
  React.useEffect(() => {
    let val = (dataObj?.serviceList ?? []).find((el) => el?.id === serviceID);
    if (!isIdentical(val, item)) {
      setItem(val);
    }
    // eslint-disable-next-line
  }, [dataObj?.catArray]);

  React.useEffect(() => {
    if (!isObjectEmpty(item)) {
      setAdded(inBasket(item, sections.services));
      setItemPrice(item);
    }
    // eslint-disable-next-line
  }, [basket, item, prices]);

  function handleChecked(value) {
    let find = inBasket(value, sections.services);
    if (!find) sectionItemAdd("basket", value, sections.services);
    else sectionItemDelete("basket", value, sections.services);
  }

  function setItemPrice(element) {
    let carID = dataObj?.usedCar?.car?.id || "all";
    let fSalePrice = 0;
    let fPrice = 0;
    let type = "";
    let priceObj = getPrice(element?.id, carID);
    if (priceObj) {
      fSalePrice = fSalePrice + priceObj?.salePrice;
      fPrice = fPrice + priceObj?.price;
      type = priceObj?.priceType;
    }
    setPriceType(type);
    setPrice(fSalePrice);
    setSale(fPrice - fSalePrice);
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <ServiceTitle />{" "}
        {item?.name && (
          <Typography sx={{ padding: "1rem" }} variant="h4">
            {item?.name}
          </Typography>
        )}
        <Box
          sx={{
            // margin: "0rem 1rem 1rem 1rem",
            display: "flex",
            flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Box sx={{ width: "100%", overflow: "auto", position: "relative" }}>
            {getImageUrlByName(item?.name) && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: dataObj?.deviceWidth?.isMobile ? "100%" : "350px",
                    padding: "4px",
                    aspectRatio: "1 / 1",
                    // maxHeight: "200px",

                    background: `url(${getImageUrlByName(item?.name)}) center center / 100% no-repeat local padding-box padding-box rgb(255, 255, 255)`,
                  }}
                />
              </Box>
            )}
            <Box
              sx={{ padding: dataObj?.deviceWidth?.isMobile ? "1rem" : "24px" }}
            >
              {/* {Object.keys(item).map((key, ind) => (
                <Box key={ind}>
                  {translateResKey(key) && (
                    <ParamRow
                      subTitle={item[key]}
                      title={translateResKey(key)}
                    />
                  )}
                </Box>
              ))} */}
              <Typography variant="body1">{item?.description}</Typography>
            </Box>
          </Box>{" "}
          <Paper
            sx={{
              width: dataObj?.deviceWidth?.isMobile ? "100%" : "33%",
              padding: dataObj?.deviceWidth?.isMobile ? "12px" : "24px",
              display: "flex",
              justifyContent: dataObj?.deviceWidth?.isMobile
                ? "space-around"
                : "flex-start",
              flexDirection: dataObj?.deviceWidth?.isMobile
                ? "row-reverse"
                : "column",
            }}
          >
            {added ? (
              <Button
                color="inherit"
                // disabled={isEmptyContext("registration")}
                fullWidth={dataObj?.deviceWidth?.isMobile ? false : true}
                onClick={() => navigate("/basket")}
                size={dataObj?.deviceWidth?.isMobile ? "small" : "large"}
                sx={{ textTransform: "none" }}
                variant="contained"
              >
                Перейти в корзину
              </Button>
            ) : (
              <Button
                // disabled={isEmptyContext("registration")}
                fullWidth={dataObj?.deviceWidth?.isMobile ? false : true}
                onClick={() => handleChecked(item)}
                size={dataObj?.deviceWidth?.isMobile ? "small" : "large"}
                sx={{ textTransform: "none" }}
                variant="contained"
              >
                Добавить в корзину
              </Button>
            )}

            {/* {price > 0 && ( */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
                paddingTop: dataObj?.deviceWidth?.isMobile ? undefined : "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0px 12px",
                  gap: "20px",
                }}
              >
                {priceType === "empty_price" ? (
                  <Typography sx={{ textAlign: "right" }} variant="h6">
                    Цена по запросу
                  </Typography>
                ) : (
                  <React.Fragment>
                    <Typography sx={{ textAlign: "right" }} variant="h6">
                      Цена:
                    </Typography>

                    <Typography
                      sx={{ textAlign: "right", textWrap: "nowrap" }}
                      variant="h6"
                    >
                      {priceType === "min_price"
                        ? "от " + price + " руб."
                        : price + " руб."}
                    </Typography>
                  </React.Fragment>
                )}
              </Box>

              {sale > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0px 12px",
                    gap: "20px",
                  }}
                >
                  <Typography sx={{ textAlign: "right" }} variant="body1">
                    Скидка:
                  </Typography>
                  <Typography
                    color="error"
                    sx={{ textAlign: "right", textWrap: "nowrap" }}
                    variant="body1"
                  >
                    - {sale} руб.
                  </Typography>
                </Box>
              )}
              <Typography
                sx={{
                  textAlign: "right",
                  textWrap: "wrap",
                  padding: "0px 12px",
                }}
                variant="body2"
              >
                {!isObjectEmpty(dataObj?.usedCar)
                  ? "для " + getCarName(dataObj?.usedCar)
                  : ""}
              </Typography>
            </Box>
            {/* )} */}
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}
