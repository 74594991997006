import { useMutation } from "@apollo/client";
import { EXT_LOGIN } from "components/gql/gql_queries";
import { GetAuthData } from "contexts/TAuthContext";
import {
  consoleBeauty,
  createTokenData,
  setLocalStorageItem,
} from "data/functions";
export function ExtLoginMutation() {
  const { setTokenData } = GetAuthData();
  const [mutation, variables] = useMutation(EXT_LOGIN, {
    onCompleted: (data) => {
      if (
        data?.auth_extLogIn.code === 200 &&
        data?.auth_extLogIn?.data?.accessToken
      ) {
        consoleBeauty("Успешная авторизация", "GraphQL", "darkgreen");
        // setToken(data?.auth_extLogIn?.data?.accessToken);
        setLocalStorageItem("tokenDataOriginal", data?.auth_extLogIn?.data);
        let obj = createTokenData(
          data?.auth_extLogIn?.data?.accessToken,
          data?.auth_extLogIn?.data?.expiresIn,
          data?.auth_extLogIn?.data?.refreshToken,
          data?.auth_extLogIn?.data?.refreshExpiresIn,
        );

        setTokenData(obj);
      }
      // else {
      //   consoleBeauty(
      //     "ExtLogin: " + data?.auth_extLogIn?.message,
      //     "GraphQL",
      //     "red",
      //   );
      //   sessionStorage.setItem(
      //     "error_" + new Date(Date.now()).toISOString(),
      //     "ExtLogin: " + data?.auth_extLogIn?.message,
      //   );
      // }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "ExtLogin: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [mutation, variables];
}
