/* eslint-disable no-unused-vars */

import React, { useEffect } from "react";

import { ExtRefreshTokenMutation } from "components/gql/mutations/ExtRefreshToken";
import { CarListQuery } from "components/gql/queris/GetCarList";
import {
  CarRecsQuery,
  onCompletedRecs,
} from "components/gql/queris/GetCarRecs";
import { ChannelsQuery } from "components/gql/queris/GetChannels";
import { GetCreditsQuery } from "components/gql/queris/GetCredits";
import { GetCreditsHistoryQuery } from "components/gql/queris/GetCreditsHistory";
import { DepartmentsQuery } from "components/gql/queris/GetDepartments";
import {
  OrdersListQuery,
  onCompletedOrders,
} from "components/gql/queris/GetOrdersList";
import { GetPrices } from "components/gql/queris/GetPrices";
import { SelfDataQuery } from "components/gql/queris/GetSelfData";
import {
  SelfInfoQuery,
  onCompletedUser,
} from "components/gql/queris/GetSelfInfo";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import {
  createTokenData,
  findInArray,
  getElementsPrices,
  getLocalStorageItem,
  getLocalToken,
  getParamToken,
  getParamTokenData,
  isAppOnline,
  isIdentical,
  setLocalStorageItem,
  updateRecs,
  updateUser,
} from "data/functions";
import { isObjectEmpty } from "data/functions";
import { gqlReducer } from "data/globals";
import { GetBasket } from "./BasketContext";
import { GetCInfo } from "./CompanyContext";

export const GQLQueryContext = React.createContext();

function GQLQueryProvider({ children }) {
  const { dataObj, pushDataObj, setDataObj } = GetData();
  const [state, dispatch] = React.useReducer(gqlReducer, {});
  const { setLogined, setToken, setTokenData, token, tokenData } =
    GetAuthData();
  const fwq = GetAuthData();

  const [selfDataQuery] = SelfDataQuery();

  window.setTok = function (str) {
    setToken(str);
  };

  const [selfInfoQuery] = SelfInfoQuery();

  DepartmentsQuery(); //onCompleted is needed

  ChannelsQuery();

  const [carRefetch] = CarListQuery();

  async function getSelfData(token) {
    let { data, error } = await selfDataQuery({
      variables: {
        token: token,
      },
    });
    if (data) {
      setDataObj(data?.auth_getSelfData?.clientID, "clientID");
      return true;
    }
    if (error) {
      setDataObj("", "clientID");
      return false;
    }
    return false;
  }

  async function checkClientID(token) {
    let { data, error } = await selfDataQuery({
      variables: {
        token: token,
      },
    });
    if (data) {
      if (
        !isIdentical(data?.auth_getSelfData?.clientID, dataObj?.clientID) ||
        isObjectEmpty(dataObj?.lastDataUpdate) ||
        new Date(dataObj?.lastDataUpdate) <
          new Date(Date.now() - 1000 * 60 * 60 * 23)
      ) {
        setDataObj(data?.auth_getSelfData?.clientID, "clientID");
        selfInfoQuery();
        ordersListQuery();
        carRefetch();
      }
      return;
    }
    if (error) {
      if (error.networkError) {
        return;
      } else {
        setDataObj("", "clientID");
        return;
      }
    }
    return;
  }

  const [ordersListQuery, ordersListVars] = OrdersListQuery();

  useEffect(() => {
    if (!isObjectEmpty(token)) {
      checkClientID(token);
    }
    // eslint-disable-next-line
  }, [token]);

  const [recQuery, recVars] = CarRecsQuery();

  useEffect(() => {
    if (!isObjectEmpty(dataObj?.carIds) && !isObjectEmpty(token)) {
      dataObj?.carIds?.forEach((carID) => {
        updateRecs(recQuery, carID, {
          dataObj,
          pushDataObj,
        });
      });
    }
    // eslint-disable-next-line
  }, [dataObj?.carIds]);

  GetCreditsQuery(); //onCompleted is needed

  GetCreditsHistoryQuery(); //onCompleted is needed

  const [refreshMutation] = ExtRefreshTokenMutation();

  React.useEffect(() => {
    checkLocalStorage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      let dataT = getParamTokenData();
      if (isObjectEmpty(dataT)) dataT = tokenData;
      if (isObjectEmpty(dataT)) dataT = getLocalStorageItem("tokenData");
      if (!isObjectEmpty(dataT)) checkTokenData(dataT, true);
    }, 30000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData]);

  async function checkLocalStorage() {
    let dataT = getParamTokenData();
    if (isObjectEmpty(dataT)) dataT = tokenData;
    if (isObjectEmpty(dataT)) dataT = getLocalStorageItem("tokenData");

    if (!isObjectEmpty(dataT)) {
      checkTokenData(dataT);
    } else {
      setLocalStorageItem(
        "failTokenData",

        "tokenData: " +
          JSON.stringify(getLocalStorageItem("tokenData")) +
          "; ParamTokenData: " +
          JSON.stringify(getParamTokenData()) +
          "; href: " +
          window.location.href,
      );
      setLocalStorageItem(
        "failToketDataReason",
        "Не найдены авторизационные данные",
      );
      setTokenData({});
    }
  }

  async function checkToken(token) {
    let { data, error } = await selfDataQuery({
      variables: {
        token: token,
      },
    });

    if (error) {
      if (error.networkError) {
        return undefined;
      } else return false;
    }
    if (data) {
      return true;
    }

    return undefined;
  }

  async function checkAndSetTokenData(tokenD, dontSet = false) {
    if (isObjectEmpty(tokenD)) return;

    let success = await checkToken(tokenD?.accessToken);
    if (success === true) {
      // console.log("Успешная проверка на активность токена");
      if (!dontSet) setTokenData(tokenD);
    } else if (success === false) {
      if (tokenD?.refreshToken) refreshToken(tokenD?.refreshToken, true);
      else {
        setLocalStorageItem(
          "failTokenData",
          "paramToken: " +
            JSON.stringify(getParamToken()) +
            "; tokenData: " +
            JSON.stringify(getLocalStorageItem("tokenData")) +
            "; ParamTokenData: " +
            JSON.stringify(getParamTokenData()) +
            "; href: " +
            window.location.href +
            "; tokenCheck: " +
            tokenD?.accessToken,
        );
        setLocalStorageItem(
          "failToketDataReason",
          "Проверка токена по tokenData не была пройдена, refreshToken отсутствует.",
        );
        setLogined(false);
      }
    }
  }

  async function checkTokenData(data, dontCheck = false) {
    if (
      isObjectEmpty(data?.accessToken) &&
      !isObjectEmpty(data?.refreshToken)
    ) {
      return refreshToken(data?.refreshToken, true);
    }
    let active =
      new Date(data?.accessTokenExpires) > new Date(Date.now()) || false;
    let activeSoon =
      new Date(data?.accessTokenExpiresSoon) > new Date(Date.now()) || false;
    let activeRefresh =
      new Date(data?.refreshTokenExpires) > new Date(Date.now()) || false;
    if (active || (active && !activeSoon)) {
      if (!activeSoon) {
        return refreshToken(data?.refreshToken, true);
      } else if (!dontCheck) {
        return checkAndSetTokenData(data);
      } else {
        setTokenData(data);
      }
    } else if (activeRefresh) {
      return refreshToken(data?.refreshToken, true);
    }
  }

  async function refreshToken(rToken, del) {
    if (rToken) {
      let { data, error } = await refreshMutation({
        variables: {
          input: {
            token: rToken,
          },
        },
      });
      if (error) {
        if (error.networkError) {
          return;
        } else {
          setLocalStorageItem(
            "failTokenData",
            "tokenData: " +
              JSON.stringify(getLocalStorageItem("tokenData")) +
              "; ParamTokenData: " +
              JSON.stringify(getParamTokenData()) +
              "; href: " +
              window.location.href +
              "; data:" +
              JSON.stringify(data) +
              "; error:" +
              JSON.stringify(error),
          );
          setLocalStorageItem(
            "failToketDataReason",
            "Токен обновления некорректный." + error?.message,
          );
          setTokenData({});
        }
        return;
      }
      if (
        data?.auth_extRefreshToken.code === 200 &&
        data?.auth_extRefreshToken?.data?.accessToken
      ) {
        setLocalStorageItem(
          "tokenDataOriginal",
          data?.auth_extRefreshToken?.data,
        );
        let obj = createTokenData(
          data?.auth_extRefreshToken?.data?.accessToken,
          data?.auth_extRefreshToken?.data?.expiresIn,
          data?.auth_extRefreshToken?.data?.refreshToken,
          data?.auth_extRefreshToken?.data?.refreshExpiresIn,
        );

        setTokenData(obj);
        return;
      }
      if (
        data?.auth_extRefreshToken.code === 401 ||
        data?.auth_extRefreshToken.code === 500
      ) {
        setLocalStorageItem(
          "failTokenData",
          "tokenData: " +
            JSON.stringify(getLocalStorageItem("tokenData")) +
            "; ParamTokenData: " +
            JSON.stringify(getParamTokenData()) +
            "; href: " +
            window.location.href +
            "; data:" +
            JSON.stringify(data),
        );
        setLocalStorageItem(
          "failToketDataReason",
          "TokenRefresh or clientID not correct." + error?.message,
        );
        setTokenData({});
      }
    }
  }

  const contextValue = {
    state: state,
  };

  const [pricesQuery] = GetPrices();
  const { cInfo } = GetCInfo();
  const { basket, sectionItemAdd } = GetBasket();

  React.useEffect(() => {
    if (!isObjectEmpty(basket?.services)) {
      getElementsPrices(
        pricesQuery,
        basket?.services,
        {
          dataObj,
          setDataObj,
        },
        { cInfo },
        { sectionItemAdd },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.usedCar]);

  return (
    <GQLQueryContext.Provider value={contextValue}>
      {children}
    </GQLQueryContext.Provider>
  );
}
export const GetGQLContext = () => {
  try {
    const context = React.useContext(GQLQueryContext);
    return context;
  } catch {
    return "";
  }
};

export { GQLQueryProvider };
