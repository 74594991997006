import { Box, LinearProgress, Paper } from "@mui/material";
import CustomizedBreadcrumbs from "components/Breadcrumbs";
import { NotFoundBlock } from "components/NotFoundBlock";
import { GetCategoriesList } from "components/gql/queris/GetCategoriesList";
import { GetCategoryElements } from "components/gql/queris/GetCategoryElements";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { getBreadcrumbs, getCategories, isObjectEmpty } from "data/functions";
import ServiceList from "pages/services/list/ServiceList";
import React, { useEffect, useState } from "react";
import FolderList from "./FolderList";

export const FolderPage = (props) => {
  const { dataObj, pushDataObj } = GetData();
  const [item, setItem] = useState(
    window.history.state
      ? (dataObj?.serviceList ?? [])?.find(
          (el) => el?.id === window.history.state?.folder,
        ) || {}
      : {},
  );
  const [br, setBr] = useState([]);
  const { cInfo } = GetCInfo();
  const [catRefetch, catVariables] = GetCategoriesList();
  const [elRefetch, elVariables] = GetCategoryElements();

  useEffect(() => {
    window.history.replaceState(
      { folder: item?.id || "" },
      "",
      window.location.pathname,
    );

    if (cInfo?.UUID) {
      getCategories(
        catRefetch,
        elRefetch,
        item,
        { dataObj, pushDataObj },
        cInfo?.UUID,
      );

      let b = getBreadcrumbs(dataObj?.serviceList, item?.id || "", "id");
      setBr(b);
    }

    // eslint-disable-next-line
  }, [item]);

  return (
    <React.Fragment>
      <Box sx={{ padding: "0 0.4rem 0.4rem 0.4rem" }}>
        <Paper sx={{ position: "sticky", top: 0, zIndex: 3 }}>
          <CustomizedBreadcrumbs array={br} func={setItem} />
        </Paper>

        <Box sx={{ height: "2px" }}>
          {((catVariables.loading && isObjectEmpty(item?.children)) ||
            (elVariables.loading && isObjectEmpty(item?.elements))) && (
            <LinearProgress sx={{ height: "2px" }} />
          )}
        </Box>

        {isObjectEmpty(
          (!isObjectEmpty(dataObj?.serviceList)
            ? dataObj?.serviceList
            : []
          ).filter((el) => (item?.id || "") === el?.parent_id),
        ) &&
          !catVariables.loading &&
          !elVariables.loading && <NotFoundBlock />}

        <FolderList parent={item} setParent={setItem} />
        <ServiceList
          checked={props.checked}
          handleChecked={props.handleChecked}
          parent={item}
        />
      </Box>
    </React.Fragment>
  );
};
