import { Alert, Box } from "@mui/material";
import {
  getLocalStorageItem,
  isIdentical,
  setLocalStorageItem,
} from "data/functions";
import { isObjectEmpty } from "data/functions";
import React from "react";
export const AlertContext = React.createContext();

function AlertProvider({ children }) {
  // const [dataObj, setDataObj] = React.useState([]);
  // const value = {
  //   dataObj, // <------ Expose Value to Consumer
  //   setDataObj, // <------ Expose Setter to Consumer
  // };

  const [alertText, setAlertText] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [isMobile, setIsMobile] = React.useState(true);

  const setAlert = React.useCallback((value, param) => {
    setAlertText(() => value);
    setAlertType(() => param);
  }, []);

  const contextValue = React.useMemo(
    () => ({
      alert,
      setAlert,
    }),
    // eslint-disable-next-line
    [alert, setAlert],
  );

  React.useEffect(() => {
    let mobileParam = getLocalStorageItem("data_deviceWidth");
    if (
      !isObjectEmpty(mobileParam) &&
      !isIdentical(mobileParam?.isMobile, isMobile)
    ) {
      setIsMobile(mobileParam?.isMobile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocalStorageItem("data_deviceWidth")]);

  React.useEffect(() => {
    if (!isObjectEmpty(alertText)) {
      setLocalStorageItem("lastAlert", alertText);
      setTimeout(() => {
        setAlertText("");
        setAlertType("");
      }, 3000);
    }
  }, [alertText, alertType]);

  return (
    <AlertContext.Provider value={contextValue}>
      {!isObjectEmpty(alertText) && (
        <Box
          sx={{
            position: "fixed",
            background: "transparent",
            top: "30%",
            left: isMobile ? "50%" : "calc(50% + 256px/2)",
            transform: "translateX(-50%)",
            zIndex: 2000,
          }}
        >
          <Alert
            icon={false}
            severity={alertType}
            sx={{ whiteSpace: "pre-line", textAlign: "center" }}
            variant="filled"
          >
            {alertText}
          </Alert>
        </Box>
      )}
      {children}
    </AlertContext.Provider>
  );
}
export const GetAlertData = () => {
  const context = React.useContext(AlertContext);
  return context;
};

export { AlertProvider };
